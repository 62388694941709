import { ref } from "vue";

export default function(){

    const API = {
        HOST:"https://back-kwaliprof.wigital.ru",
        PUBLIC_API:"https://back-kwaliprof.wigital.ru/api/public",
    }

    const api = async (path = '', params = {}) => {
        return await fetch(API.PUBLIC_API + path).then(r => r.json());
    }

    const loadProducts = async () => {
        let products = await api('/products?page_size=100').then(r => r.data.items);
        return products.map(p => {
            p.image.src = p?.image?.src ? API.HOST + p?.image?.src : '';
            return p;
        });
    }

    let baseParams = {
        order_by:'id',
        order_dir:'asc',
        page_size: 1100,
    }
    const loadProfiles = async (params = {}) => {
        let p =  new URLSearchParams({...baseParams, ...params});
        return  await api('/product-profiles?' + p.toString()).then(r => r.data.items)
    }
    const loadGlasses = async (params = {}) => {
        let p =  new URLSearchParams({...baseParams, ...params});
        return await api('/glasses?' + p.toString()).then(r => r.data.items)
    }
    const loadColors = async (params = {}) => {
        let p =  new URLSearchParams({...baseParams, ...params});
        return await api('/colors?' + p.toString()).then(r => r.data.items)
    }
    const loadPrices = async (params = {}) => {
        let p =  new URLSearchParams({...baseParams, ...params});
        return await api('/prices?' + p.toString()).then(r => r.data.items)
    }

    const loadProductById = async (productId = null) => {
        return await api(`/products/${productId}`).then(r => r.data)
    }

    const loadPrice = async (productId = null, profileId = null) => {
        let p =  new URLSearchParams(baseParams);
        const items = await api('/prices?' + p.toString()).then(r => r.data.items)
        return items.find(i => i.product_id == productId && i.profile_id == profileId)
    }



    return {
        loadProductById,
        loadProducts,
        loadProfiles,
        loadGlasses,
        loadColors,
        loadPrices,
        loadPrice,
    };
}