<template>

	<br><br>
	<div v-if="!params.isLoading">
		<List v-if="!isVariant"  />
		<Variant v-if="isVariant"/>
	</div>

</template>

<script setup>

import { computed, onMounted, ref } from 'vue';
import Variant from './components/Variant.vue';
import List from './components/List.vue';

const params = ref({
	isLoading:true,
	product_id:null,
	type_id:null,
})

const isVariant = computed(() => params.value?.product_id && params.value?.type_id)

onMounted(async () =>  {
	const p = (new URL(window.location.href)).searchParams;
	params.value.product_id = p.get('product_id');
	params.value.type_id = p.get('type_id');
	params.value.isLoading = false;
})

</script>

<style lang="scss">

.calc{
    display: block;
    width: 100%;
    min-height: 500px;
    font-size: 16px;
}

.calc-main{
    display: grid;
    grid-template-columns: 500px 1fr;

    border: 2px solid whitesmoke;
    box-shadow: 0px 1px 10px 0px #00000026, 0px 0px 0px 1px #0000000f;
    border-radius: 10px;

}


.calc-main-aside{
    background: whitesmoke;
    padding: 1rem
}


.calc-main-aside{
    padding: 2rem;
}

.calc-main-aside-header{
    display: flex;
    margin-bottom: 1rem;
}
.calc-main-aside-price{
    font-weight: 600;
    color: #62ad0c;
    font-size: 26px;
}

.calc-main-aside-header .calc-main-aside-price{
    margin-left: auto;
}

.calc-main-aside-title{
    font-size: 22px;
    font-weight: 600;
    display: block;

}



.calc-main-aside-row{
    display: grid;
    grid-template-columns: 1fr 300px;
    margin-bottom: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.calc-main-aside-row-title{
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}
.calc-main-aside-row-input{
    position: relative;
}
.notice{
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 500;
    color: #8bc34a;
}


.calc-main-aside-row-input select,
.calc-main-aside-row-input input[type=text],
.calc-main-aside-row-input input[type=number]
{
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 10px;
    border: 1px solid #e1e5e7;
    border-radius: 4px;
    font-size: 15px;
    line-height: 1em;


}

.calc-main-aside-row-info{

}

.calc-main-aside-row .bloquote{
    --background-color: lightgoldenrodyellow;
    padding: 10px 12px;
    background: var(--background-color);
    font-size: 13px;
    line-height: 1.5rem;
    border-radius: 5px;
    border: 1px dashed #0000001a;
}


.calc-main-aside-row .bloquote.green{
    --background-color: #d5ffc7;
}

.calc-main-visual-double{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.calc-main-visual-double-section{
    border-bottom: 2px solid whitesmoke;
}

.calc-main-visual-double-section:not(:last-child){
    border-right: 2px solid whitesmoke;
}

.calc-main-visual-double-section img{
    max-height: 180px;
}


.calc-main-visual{
    text-align: center;
}
.calc-main-visual-title{
    background: whitesmoke;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px;
}
.calc-main-visual-picture{
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
}
.calc-main-visual-picture img{
    max-width: 100%;

}

.calc-main-header{
    display: flex;
    box-sizing: border-box;
    padding: 1rem;
}

.calc-main-header-title{
    font-size: 20px;
    font-weight: 600;
}

.calc-main-header .calc-main-header-buttons{
    margin-left: auto;
}

.calc-main-header-btn{
    background: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
}

.calc-main-header-btn .icon{
    width: 24px;
    height: 24px;
}
</style>
