<template>
    <div class="calc-steps">
        <div class="calc-step" v-for="product in items">
            <a :href="`?product_id=${product.id}&type_id=${product.type_id}`" class="calc-step-container" >
                <div class="calc-step-image">
                    <img :src="product.image?.src " :alt="product.name">
                </div>
                <div class="calc-step-title">{{product.name}}</div>
            </a>
        </div>
    </div>
</template>

<script setup async>

    import useApi from "@/composables/useApi";
    import {onMounted, ref} from "vue";

    let {loadProducts} = useApi();
    let items = ref([]);
    let loadItems = async () => {
        items.value = await loadProducts();
    }

    onMounted(async () => await loadItems())

</script>

<style lang="scss">

.calc-steps{
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    .calc-step{
        position: relative;
        background: #f8f8f8;
        text-align: center;
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #dfdfdf;
        transition: 100ms box-shadow;
        overflow: hidden;
        height: 100%;
        cursor: pointer;
        border-radius: 10px;
    }
    .calc-step:hover{
        background: linear-gradient(0deg, #d5ef28, #aec517);
        border: 1px solid #d5ef28;
    }

    .calc-step:hover .calc-step-image{
        transform: scale(1.02);
    }



    .calc-step-container{
        text-decoration: none;
        color: #000;
        padding: 2rem;
        display: block;
    }




    .calc-step-image{
        display: block;
        margin: 0 auto 1rem;
        transition: 200ms;
    }

    .calc-step-image img{
        width: 100%;
        display: block;
        aspect-ratio: 1;
        margin: 0 auto 1rem;
        max-width: 240px;
        --bloack: #0000001f;
        filter: drop-shadow(0px 4px 8px var(--bloack));
    }
    .calc-step-title{
        text-align: center;
    }

    .calc-step-title{
        text-transform: uppercase;
        font-weight: 500;
    }

}

</style>